import { API_ADVERTISER_ENDPOINT } from '@yim/server/constance';
import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Influencer as InfluencerModel } from '@yim/server/models';
import { BehaviorSubject } from 'rxjs';
import { map } from 'rxjs/operators';

import { environment } from '../../../environments/environment';

export interface SetCard {
  user: string;
  date: number;
  // social_id: number;
  setcard?: InfluencerModel;
}

export interface Period {
  id: number;
  valid_from: number;
  valid_until: number;
  profile_requests: number;
  invoice: string;
  period: string;
  is_current?: boolean;
}

export interface SetCards { [key: number]: SetCard; }

@Injectable({ providedIn: 'root' })
export class HistoryService {

  private periodsBehaviour = new BehaviorSubject<{ [period_id: number]: Period }>({ });
  private setCardsBehaviour = new BehaviorSubject<{ [period_id: number]: SetCards }>({});

  private patchSetCards(period_id: number, influencers: SetCards) {
    const v = this.setCardsBehaviour.getValue();
    this.setCardsBehaviour.next({ ...v, [period_id]: influencers });
  }

  constructor(
    private http: HttpClient,
    @Inject(API_ADVERTISER_ENDPOINT) private readonly endpoint: string,
  ) { }

  get PeriodsHashMap() {
    return this.periodsBehaviour.asObservable();
  }

  get Periods() {
    return this.PeriodsHashMap.pipe(
      map(v => Object.values(v) as Period[])
    );
  }

  get SetCards() {
    return this.setCardsBehaviour.asObservable();
  }

  fetchPeriods() {
    return this.http.get<Period[]>(`${environment.API}/${this.endpoint}/influencer/subscriptions`)
    .subscribe(v => {
      const old = this.periodsBehaviour.getValue();
      v.forEach(period => old[period.id] = period);
      this.periodsBehaviour.next(old);
    });
  }

  fetchPeriod(period_id: number) {
    return this.http.get<SetCards>(`${environment.API}/${this.endpoint}/influencer/bigsetcardhistory/${period_id}`)
    .subscribe(v => {
        this.patchSetCards(period_id, Object.keys(v).length > 0 ? v : {});
    });
  }
}
